import { AngularSplitModule } from 'angular-split';
import { ListService } from './services/list.service';
import { ListInputPanelComponent } from './components/list-input-panel.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ListInputPanelComponent
  ],
  imports: [
    CommonModule,
    FormsModule,

    // Material
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,

    //
    AngularSplitModule
  ],
  exports: [
    ListInputPanelComponent
  ],
  providers:[
    ListService
  ]
})
export class ListInputPanelModule { }
