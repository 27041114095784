<as-split direction="horizontal">
  <as-split-area [size]="50" class="panel">

    <mat-form-field appearance="fill" class="full-height">
      <mat-label>Input YAZIO shoping list</mat-label>
      <textarea matInput [(ngModel)]="inputList"></textarea>
    </mat-form-field>

    <button mat-raised-button color="primary" (click)="importList()">Import List</button>

  </as-split-area>
  <as-split-area [size]="50" class="panel">
    <ng-container *ngIf="objStr">
      <pre class="full-height">{{objStr}}</pre>
      <button mat-raised-button color="primary" (click)="downLoadCsv()">Download CSV</button>
    </ng-container>
  </as-split-area>
</as-split>
