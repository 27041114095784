import { ListService } from './../services/list.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-list-input-panel',
  templateUrl: './list-input-panel.component.html',
  styleUrls: ['./list-input-panel.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ListInputPanelComponent implements OnInit {

  inputList = ``;

  objStr = ``;

  constructor(private _listService: ListService) { }

  ngOnInit(): void {
  }

  importList(): void{
    const items = this._listService.parse(this.inputList);
    this.objStr = this._listService.compile(items);
  }

  downLoadCsv(): void{
    const a = document.createElement('a');
    const blob = new Blob([this.objStr], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'OutOfMilkList.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

}
