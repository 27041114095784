export const CATEGORY_LOOKUP_MAP = new Map<string, string>([
  ['Cashewmus', 'Aufstriche'],
  ['Erdnussbutter (natur)', 'Aufstriche'],
  ['Mandelmus', 'Aufstriche'],
  ['Vollkorn-Müsli', 'Cerialien'],
  ['Lachs (Scheiben)', 'Fisch'],
  ['Thunfisch (Dose)', 'Fisch'],
  ['Aubergine', 'Gemüse'],
  ['Basilikum', 'Gemüse'],
  ['Blumenkohl', 'Gemüse'],
  ['Brokkoli', 'Gemüse'],
  ['Champignons', 'Gemüse'],
  ['Cocktailtomaten', 'Gemüse'],
  ['Feldsalat', 'Salat'],
  ['Frühlingszwiebel', 'Gemüse'],
  ['Frühlingszwiebeln', 'Gemüse'],
  ['Grüne Bohnen', 'Gemüse'],
  ['Kartoffeln', 'Gemüse'],
  ['Lauch (Porree)', 'Gemüse'],
  ['Möhre (Karotte)', 'Gemüse'],
  ['Möhren (Karotten)', 'Gemüse'],
  ['Paprika (rot)', 'Gemüse'],
  ['Paprikas (gelb)', 'Gemüse'],
  ['Paprikas (rot)', 'Gemüse'],
  ['Radieschen', 'Gemüse'],
  ['Rote Chilis', 'Gemüse'],
  ['Salatgurke', 'Gemüse'],
  ['Salatgurken', 'Gemüse'],
  ['Spinat', 'Salat'],
  ['Tomate', 'Gemüse'],
  ['Tomaten', 'Gemüse'],
  ['Zucchini', 'Gemüse'],
  ['Zucchinis', 'Gemüse'],
  ['Zwiebel', 'Gemüse'],
  ['Zwiebeln', 'Gemüse'],
  ['Auberginen', 'Gemüse'],
  ['Pastinaken', 'Gemüse'],
  ['Blattspinat', 'Salat'],
  ['Oliven (schwarz, entsteint)', 'Gemüse'],
  ['Avocado', 'Gemüse'],
  ['Avocados', 'Gemüse'],
  ['Paprika (gelb)', 'Gemüse'],
  ['Römersalat (Romanasalat)', 'Gemüse'],
  ['Orangensaft (100% Frucht)', 'Getränke'],
  ['Amaranth (gepufft)', 'Getreide und Backwaren'],
  ['Basmati-Reis', 'Getreide und Backwaren'],
  ['Chiasamen', 'Getreide und Backwaren'],
  ['Couscous', 'Getreide und Backwaren'],
  ['Dinkelmehl (Vollkorn)', 'Getreide und Backwaren'],
  ['Haferflocken (Vollkorn)', 'Getreide und Backwaren'],
  ['Haselnuss', 'Getreide und Backwaren'],
  ['Kakaopulver', 'Getreide und Backwaren'],
  ['Knäckebrot', 'Getreide und Backwaren'],
  ['Knäckebrote', 'Getreide und Backwaren'],
  ['Kokosflocken', 'Getreide und Backwaren'],
  ['Leinsamen', 'Getreide und Backwaren'],
  ['Leinsamen (geschrotet)', 'Getreide und Backwaren'],
  ['Mandeln', 'Getreide und Backwaren'],
  ['Mehl', 'Getreide und Backwaren'],
  ['Pumpernickel', 'Getreide und Backwaren'],
  ['Quinoa', 'Getreide und Backwaren'],
  ['Roggenbrot (Vollkorn)', 'Getreide und Backwaren'],
  ['Rote Linsen', 'Getreide und Backwaren'],
  ['Soja-Granulat', 'Getreide und Backwaren'],
  ['Toastbrot (Vollkorn)', 'Getreide und Backwaren'],
  ['Tortilla Wraps', 'Getreide und Backwaren'],
  ['Sesam', 'Getreide und Backwaren'],
  ['Sonnenblumenkerne', 'Getreide und Backwaren'],
  ['Backpulver', 'Getreide und Backwaren'],
  ['Chilipulver', 'Gewürze'],
  ['Chilisauce', 'Gewürze'],
  ['Currypaste', 'Gewürze'],
  ['Koriander', 'Gewürze'],
  ['Kräutersalz', 'Gewürze'],
  ['Kreuzkümmel', 'Gewürze'],
  ['Thymian', 'Gewürze'],
  ['Vanille', 'Gewürze'],
  ['Kichererbsen (Glas/Dose)', 'Konserven'],
  ['Kidney-Bohnen (Glas/Dose)', 'Konserven'],
  ['Kokosmilch (Dose)', 'Konserven'],
  ['Passierte Tomaten', 'Konserven'],
  ['Pesto (grün)', 'Konserven'],
  ['Tomatenmark', 'Konserven'],
  ['Weiße Bohnen', 'Konserven'],
  ['Zuckermais (Glas/Dose)', 'Konserven'],
  ['Butter', 'Milchprodukte und Ei'],
  ['Crème Légère', 'Milchprodukte und Ei'],
  ['Ei', 'Milchprodukte und Ei'],
  ['Eier', 'Milchprodukte und Ei'],
  ['Frischkäse', 'Milchprodukte und Ei'],
  ['Geriebener Käse', 'Milchprodukte und Ei'],
  ['Hüttenkäse', 'Milchprodukte und Ei'],
  ['Joghurt (natur)', 'Milchprodukte und Ei'],
  ['Mandelmilch', 'Milchprodukte und Ei'],
  ['Mandelmilch (ungesüßt)', 'Milchprodukte und Ei'],
  ['Milch (fettarm 1,5% Fett)', 'Milchprodukte und Ei'],
  ['Mozzarella (light)', 'Milchprodukte und Ei'],
  ['Parmesan', 'Milchprodukte und Ei'],
  ['Saure Sahne', 'Milchprodukte und Ei'],
  ['Schafskäse (light)', 'Milchprodukte und Ei'],
  ['Sojajoghurt', 'Milchprodukte und Ei'],
  ['Tofu', 'Milchprodukte und Ei'],
  ['Tofu (gebröselt)', 'Milchprodukte und Ei'],
  ['Tofu gehackt', 'Milchprodukte und Ei'],
  ['Schmand (ca. 24% Fett)', 'Milchprodukte und Ei'],
  ['Cashewnuss', 'Nüsse und Trockenobst'],
  ['Cashewnüsse', 'Nüsse und Trockenobst'],
  ['Cranberries (getrocknet)', 'Nüsse und Trockenobst'],
  ['Erdnüsse', 'Nüsse und Trockenobst'],
  ['Goji Beeren (getrocknet)', 'Nüsse und Trockenobst'],
  ['Haselnüsse', 'Nüsse und Trockenobst'],
  ['Pinienkerne', 'Nüsse und Trockenobst'],
  ['Rosinen', 'Nüsse und Trockenobst'],
  ['Walnuss', 'Nüsse und Trockenobst'],
  ['Walnüsse', 'Nüsse und Trockenobst'],
  ['Datteln (getrocknet)', 'Nüsse und Trockenobst'],
  ['Feigen (getrocknet)', 'Nüsse und Trockenobst'],
  ['Apfel', 'Obst'],
  ['Äpfel', 'Obst'],
  ['Banane', 'Obst'],
  ['Bananen', 'Obst'],
  ['Heidelbeeren', 'Obst'],
  ['Ingwer', 'Obst'],
  ['Kiwi', 'Obst'],
  ['Kiwis', 'Obst'],
  ['Limette', 'Obst'],
  ['Limetten', 'Obst'],
  ['Limettensaft', 'Obst'],
  ['Mango', 'Obst'],
  ['Mangos', 'Obst'],
  ['Orange', 'Obst'],
  ['Orangen', 'Obst'],
  ['Pfirsich', 'Obst'],
  ['Weintraube (rot)', 'Obst'],
  ['Weintraube (weiß)', 'Obst'],
  ['Zitrone', 'Obst'],
  ['Zitronen', 'Obst'],
  ['Pfirsiche', 'Obst'],
  ['Birnen', 'Obst'],
  ['Weintrauben (weiß)', 'Obst'],
  ['Kokosöl', 'Öle und Fette'],
  ['Tortilla Wrap', 'Sonstiges'],
  ['Penne (Nudeln)', 'Teigwaren'],
  ['Seitan', 'Teigwaren'],
  ['Spaghetti', 'Teigwaren'],
  ['Tortellini (Ricotta Spinat)', 'Teigwaren'],
  ['Erbsen', 'TK'],
  ['Himbeeren', 'TK'],
  ['Kräuter (TK, italienisch)', 'TK'],
  ['Kräutermischung (TK)', 'TK'],
  ['Petersilie', 'TK'],
  ['Schnittlauch', 'TK'],
  ['Birne', 'Obst'],
  ['Rucola', 'Salat'],
  ['Schalotten', 'Gemüse'],
  ['Bratöl', 'Öle und Fette'],
  ['Olivenöl', 'Öle und Fette'],
  ['Salatblatt', 'Gemüse'],
  ['Camembert (laktosefrei, Dreiviertelfettstufe)', 'Milchprodukte und Ei'],
  ['Salatblätter', 'Gemüse'],
  ['Kaisergemüse (TK)', 'TK'],
  ['Rotkohl (frisch)', 'Konserven'],
  ['Lachs', 'TK'],
  ['Reiswaffeln (ungesalzen)', 'Getreide und Backwaren'],
  ['Gouda', 'Milchprodukte und Ei'],
  ['Paprikapulver (rosenscharf)', 'Gewürze'],
  ['Kürbiskerne', 'Nüsse und Trockenobst'],
  ['Weinstein Backpulver', 'Getreide und Backwaren'],
  ['Honig', 'Aufstriche'],
  ['Milch (fettarm, 1,5% Fett)', 'Milchprodukte und Ei'],
  ['Brokkolis', 'Gemüse'],
  ['Staudenselleries', 'Gemüse'],
  ['Paprikas (grün)', 'Gemüse'],
  ['Schlagsahne', 'Milchprodukte und Ei'],
  ['Erdnussbutter (natur, ungesüßt)', 'Aufstriche'],
  ['Agavendicksaft', 'Sonstiges'],
  ['Ahornsirup', 'Aufstriche'],
  ['Banane, reif', 'Obst'],
  ['Blaubeeren', 'TK'],
  ['Chilischote', 'Gemüse'],
  ['Currypaste, grün', 'Gewürze'],
  ['Currypaste, rot', 'Gewürze'],
  ['Dinkelmehl', 'Getreide und Backwaren'],
  ['Erdnüsse, geröstet', 'Nüsse und Trockenobst'],
  ['Erdnussmus', 'Aufstriche'],
  ['Galiamelone', 'Obst'],
  ['Garnelen, gekocht', 'TK'],
  ['Gemüsebrühe', 'Gewürze'],
  ['Gouda, gerieben', 'Milchprodukte und Ei'],
  ['Haferflocken', 'Getreide und Backwaren'],
  ['Ingwer, gemahlen', 'Obst'],
  ['Joghurt, 0,1% Fett', 'Milchprodukte und Ei'],
  ['Karotte', 'Gemüse'],
  ['Karotten', 'Gemüse'],
  ['Kirschtomaten', 'Gemüse'],
  ['Knoblauchzehe', 'Gemüse'],
  ['Knoblauchzehen', 'Gemüse'],
  ['Kokosmilch', 'Konserven'],
  ['Kokosmilch, fettreduziert', 'Konserven'],
  ['Kokosraspel', 'Getreide und Backwaren'],
  ['Koriander, frisch', 'Gewürze'],
  ['Lasagne-Platten, ohne Ei', 'Getreide und Backwaren'],
  ['Lauch', 'Gemüse'],
  ['Linsen, braun', 'Getreide und Backwaren'],
  ['Mais, Konserve', 'Konserven'],
  ['Mandelblättchen', 'Getreide und Backwaren'],
  ['Mandeldrink, ungesüßt', 'Milchprodukte und Ei'],
  ['Mandeln, gehackt', 'Nüsse und Trockenobst'],
  ['Margarine', 'Milchprodukte und Ei'],
  ['Milch, 1,5% Fett', 'Milchprodukte und Ei'],
  ['Minzblätter, frisch', 'Gewürze'],
  ['Muskatnuss, gerieben', 'Gewürze'],
  ['Natur-Tofu', 'Milchprodukte und Ei'],
  ['Naturtofu', 'Milchprodukte und Ei'],
  ['Oregano, gerebelt', 'Gewürze'],
  ['Paranusskerne', 'Nüsse und Trockenobst'],
  ['Petersilie, frisch', 'Gewürze'],
  ['Pfeffer und Salz', 'Gewürze'],
  ['Ramen-Nudeln', 'Getreide und Backwaren'],
  ['Rapsöl', 'Öle und Fette'],
  ['Reis', 'Getreide und Backwaren'],
  ['Salz', 'Gewürze'],
  ['Schwarze Bohnen, Konserve', 'Konserven'],
  ['Senf', 'Gewürze'],
  ['Sojadrink', 'Milchprodukte und Ei'],
  ['Sojasauce', 'Gewürze'],
  ['Spinat, frisch', 'Gemüse'],
  ['Streukäse, vegan', 'Milchprodukte und Ei'],
  ['Süßkartoffel', 'Gemüse'],
  ['Süßstoff', 'Sonstiges'],
  ['Thunfisch, in Wasser', 'Konserven'],
  ['Thymian, frisch', 'Gewürze'],
  ['Tomaten, stückig', 'Konserven'],
  ['Vollkornnudeln', 'Getreide und Backwaren'],
  ['Wasser', 'Sonstiges'],
  ['Zimt, gemahlen', 'Gewürze'],
  ['Zitrone, Bio', 'Obst'],
  ['Zitronensaft', 'Obst'],

])
