import { ItemLine, Measurement } from './../model/item-line';
import { ListParser } from './../model/parser.interface';
import { MeasurementUnit } from '../model/measurement-unit.enum';
import { ParserUtils } from '../utils/parser-utils';


interface YazioMeasurement {
  value: string;
  fraction: string;
  unit: string;
}

export class YazioListParser implements ListParser {

  private ITEM_LINE_REGEX = /^(?:・\s*)?(?:([^a-zA-Z]+)\s*)?([^(\n\r]*)((?:\([^)]*\)\s?)+)$/;
  private MEASUREMENT_PART_REGEX = /\(([^a-zA-Z][^)]+)\)/;
  private MEASUREMENT_REGEX = /([0-9]*)([^\s]*)\s(.*)/;

  private UNIT_MAP = new Map<string, MeasurementUnit>([
    ['', MeasurementUnit.PIECES],
    ['g', MeasurementUnit.GRAM],
    ['TL', MeasurementUnit.TEASPOON],
    ['tsp.', MeasurementUnit.TEASPOON],
    ['ml', MeasurementUnit.MILLILITRE],
    ['mL', MeasurementUnit.MILLILITRE],
    ['EL', MeasurementUnit.TABLESPOON],
    ['tbsp.', MeasurementUnit.TABLESPOON],
    ['Scheiben', MeasurementUnit.PIECES],
  ]);

  constructor() { }

  parse(list: string): Map<string, ItemLine[]> {

    const items: Map<string, ItemLine[]> = new Map();

    if (!list) { return items; }
    const lines: string[] = list.split(`\n`);

    for (let line of lines) {
      line = line.trim();
      if (!line || !this.isItemLine(line)) { continue; }

      const itemLine: ItemLine | null = this.parseLine(line);
      if (itemLine == null) { continue; }
      const item = items.get(itemLine.item) || [];
      item.push(itemLine);
      items.set(itemLine.item, item);
    }

    return items;
  }

  private isItemLine(line: string): boolean {
    return this.ITEM_LINE_REGEX.test(line);
  }

  private parseLine(line: string): ItemLine | null {

    const match = this.ITEM_LINE_REGEX.exec(line);

    if (match == null || match.length === 0) { return null; }

    const measurements = this.parseMeasurementPart(match[3]);

    const count = ParserUtils.parseDouble(match[1]);

    if (count > 0) {
      measurements.push({
        value: count,
        unit: MeasurementUnit.PIECES,
        originalUnit: ''
      });
    }

    return {
      item: match[2] ? match[2].trim() : '',
      measurements,
      category: ''
    };
  }

  private parseMeasurementPart(measurmentsPart: string | null): Measurement[] {
    if (measurmentsPart == null || measurmentsPart.trim().length === 0) {
      return [];
    }

    const regexSubParts = new RegExp(this.MEASUREMENT_PART_REGEX, 'g');
    const subParts = measurmentsPart.match(regexSubParts);

    if (subParts == null || subParts.length === 0) { return []; }

    const ret: Measurement[] = [];
    for (const part of subParts) {
      const matches = part.match(this.MEASUREMENT_PART_REGEX);

      if (matches == null || matches.length === 0) { continue; }

      ret.push(...this.parseMeasurementList(matches[1]));
    }

    return ret;

  }

  private parseMeasurementList(measurments: string | null): Measurement[] {

    if (measurments == null || measurments.length === 0) {
      return [];
    }

    const ret: Measurement[] = [];
    const list = measurments.split(', ');

    for (const measurementStr of list) {
      const measurment = this.parseMeasurement(measurementStr);
      if (measurment) { ret.push(this.mapToMeasurement(measurment)); }
    }

    return ret;
  }

  private parseMeasurement(measurement: string): YazioMeasurement | null {

    if (!measurement || !measurement.trim()) { return null; }

    const match = measurement.match(this.MEASUREMENT_REGEX);

    return match ? {
      value: match[1],
      fraction: match[2],
      unit: match[3]
    } : null;
  }

  private mapToMeasurement(yMeasurement: YazioMeasurement): Measurement {
    return {
      value: ParserUtils.parseDouble(yMeasurement.value) + ParserUtils.parseFraction(yMeasurement.fraction),
      unit: this.toUnit(yMeasurement.unit),
      originalUnit: yMeasurement.unit
    };
  }

  private toUnit(unit: string): MeasurementUnit {

    const mUnit = this.UNIT_MAP.get(unit.trim());

    if (!mUnit) {
      return MeasurementUnit.NOT_SPECIFIED;
    }
    return mUnit;
  }
}
