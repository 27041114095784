import { CATEGORY_LOOKUP_MAP } from './../model/category-lookup';
import { YazioListParser } from './yazio-list-parser';
import { ListCompiler } from './../model/compiler.interface';
import { ListParser } from './../model/parser.interface';
import { ItemLine, Measurement } from './../model/item-line';
import { OutOfMilkListCompiler } from './out-of-milk-list-compiler';
import { MeasurementUnit } from '../model/measurement-unit.enum';
import { Injectable } from '@angular/core';

@Injectable()
export class ListService {

  constructor() { }

  parse(list: string): ItemLine[] {
    const parser: ListParser = this.getYazioListParser();

    const items = parser.parse(list);
    let itemList = [...items.values()].map(item => this.aggregateItem(item));
    itemList = this.sortItemList(itemList);
    itemList.forEach(item => this.applyCategory(item));
    return itemList;
  }

  compile(list: ItemLine[]): string {
    const compiler: ListCompiler = new OutOfMilkListCompiler();

    return compiler.compile(list);

  }

  private getYazioListParser(): ListParser {
    return new YazioListParser();
  }

  private sortItemList(items: ItemLine[]): ItemLine[] {
    if (items.length < 2) { return [...items]; }
    const sortedList = [...items].sort((itemA, itemB) => itemA.item.localeCompare(itemB.item));
    return [...sortedList];
  }

  private aggregateItem(lines: ItemLine[]): ItemLine {

    if (lines.length === 0) { throw Error('Illigal argument exception: item length must not be 0'); }

    const item = lines[0].item;
    const measurements: Measurement[] = this.aggregateMeasurements(lines.map(line => line.measurements));
    const category =  '';
    return { item, measurements, category };
  }

  private applyCategory(item: ItemLine): void {
    const category: string | undefined = CATEGORY_LOOKUP_MAP.get(item.item);

    if(category == null){
      console.log(`### no category for:  ${item.item}`);
    }

    item.category = category || '';
  }

  private aggregateMeasurements(measurements: Measurement[][]): Measurement[] {
    const flattenM = measurements.reduce((arr, v) => (arr.push(...v), arr), []);
    const units: MeasurementUnit[] = [...new Set(flattenM.map(m => m.unit))];
    const originalUnits: string[] = [...new Set(flattenM.map(m => m.originalUnit))];
    const aggregatedMeasurements: Measurement[] = [];


    units.forEach(
      (unit: MeasurementUnit, index: number) => {
        const sum = flattenM.filter(m => m.unit === unit).reduce((pv, cv) => pv + cv.value, 0);
        aggregatedMeasurements.push({
          value: sum,
          unit,
          originalUnit: originalUnits[index]
        });
      }
    );

    return aggregatedMeasurements;
  }
}
