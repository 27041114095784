import { ItemLine, Measurement } from './../model/item-line';
import { ListCompiler } from './../model/compiler.interface';
import { MeasurementUnit } from '../model/measurement-unit.enum';
import { FixedLengthArray } from '../utils/compiler-utils';


type OutOfMilkLine = FixedLengthArray<[
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string
]>;

enum OutOfMilkField {
  description,
  upc,
  quantity,
  done,
  unit,
  price,
  note,
  coupon_amount,
  coupon_type,
  coupon_note,
  tax_free,
  category
}



class OutOfMilkListBuilder {

  private CSV_HEADER: string[] = Object
    .keys(OutOfMilkField)
    .map((key: any) => OutOfMilkField[key])
    .filter(value => typeof value === 'string') as string[];


  private DEFAULT_UNIT = MeasurementUnit.NOT_SPECIFIED;


  private _list: Array<OutOfMilkLine>;

  constructor() {
    this._list = [];
  }

  build(): string {

    if (this._list.length === 0) {
      return '';
    }

    let csv = this.toStrLine(this.CSV_HEADER);
    for (const line of this._list) {
      csv += this.toStrLine(line);
    }

    return csv;
  }

  private toStrLine(items: string[] | OutOfMilkLine): string {
    return items.join(',') + '\n';
  }

  setDescription(line: OutOfMilkLine, descr: string): void {
    this.setField(line, OutOfMilkField.description, descr);
  }

  setQuantity(line: OutOfMilkLine, quantity: number): void {

    const options: Intl.NumberFormatOptions = {
      style: 'decimal',
      useGrouping: false,
      minimumIntegerDigits: 1.0,
      minimumFractionDigits: 2.0,
    };
    const formatter = new Intl.NumberFormat('en-US', options);


    const numStr = formatter.format(quantity);
    this.setField(line, OutOfMilkField.quantity, numStr);
  }

  setUnit(line: OutOfMilkLine, unit: MeasurementUnit): void {
    this.setField(line, OutOfMilkField.unit, MeasurementUnit[unit]);
  }

  setNote(line: OutOfMilkLine, note: string): void {
    this.setField(line, OutOfMilkField.note, note);
  }

  setCategory(line: OutOfMilkLine, category: string): void {
    this.setField(line, OutOfMilkField.category, category);
  }

  addLine(): OutOfMilkLine {
    const length = this._list.push(this.newLine());
    return this._list[length - 1];
  }

  private setField(line: OutOfMilkLine, field: OutOfMilkField, value: string): void {
    line[field] = this.sanitizeStr(value);
  }

  private newLine(): OutOfMilkLine {
    const line: OutOfMilkLine = [
      '',
      '',
      '1.0',
      'False',
      MeasurementUnit[this.DEFAULT_UNIT],
      '0.0',
      '',
      '0.0',
      'AMOUNT',
      '',
      'False',
      ''
    ];

    return line;
  }

  private sanitizeStr(value: string): string {
    const numRegEx = /([\d]+),([\d]+)/;
    return value
      .replace(numRegEx, '$1.$2')
      .replace(/,/g, ';');
  }
}

export class OutOfMilkListCompiler implements ListCompiler {

  compile(list: ItemLine[]): string {

    const builder = new OutOfMilkListBuilder();

    for (const line of list) {
      this.handleLine(builder, line);
    }

    return builder.build();
  }

  private handleLine(builder: OutOfMilkListBuilder, line: ItemLine): void {
    const outOfMilkLine = builder.addLine();

    builder.setDescription(outOfMilkLine, line.item);

    const primMeasurement = this.getPrimaryMeasurement(line.measurements);

    builder.setQuantity(outOfMilkLine, primMeasurement[1]);
    builder.setUnit(outOfMilkLine, primMeasurement[0]);

    builder.setNote(outOfMilkLine, this.toNote(line.measurements));

    builder.setCategory(outOfMilkLine, line.category);
  }

  private getPrimaryMeasurement(measurements: Measurement[]): [MeasurementUnit, number] {

    if (measurements.length === 0) {
      return [MeasurementUnit.NOT_SPECIFIED, 0.0];
    }

    const pieces = measurements.filter(measurement => measurement.unit === MeasurementUnit.PIECES);

    if (pieces && pieces.length) {
      return [MeasurementUnit.PIECES, pieces.map(a => a.value).reduce((a, b) => a + b)];
    }

    const first = measurements[0];

    return [first.unit, first.value];
  }

  private toNote(measurements: Measurement[]): string {
    const unitStrs = measurements.map(m => `${m.value} ${m.originalUnit}`);
    return unitStrs.join('; ');
  }
}
