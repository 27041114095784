export enum MeasurementUnit {
  PINT,
  MILLILITRE,
  DECILITRE,
  LITRE,
  KILOGRAM,
  GRAM,
  TEASPOON,
  TABLESPOON,
  GALLON,
  CUP,
  OUNCE,
  QUART,
  POUND,
  LARGE,
  SMALL,
  DOZEN,
  ROLL,
  BUNCH,
  CAN,
  JAR,
  PACK,
  CASE,
  BOX,
  BOTTLE,
  BAG,
  PIECES,
  EACH,
  NOT_SPECIFIED
}
