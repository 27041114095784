import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class DataService{

    constructor(private _http: HttpClient ){}

    getData(): Observable<any>{
      return this._http.get('https://psvj.de/server.php');
    }


}
